export const updateViolationsFormData = (answers, newViolation, updateFormData, setNewViolation) => {
  const violationData = answers.violations_data;
  const firstInsuranceYear = parseInt(answers.first_insuranced_year, 10);

  if (violationData[newViolation.type]) {
    const dataToUpdate = violationData[newViolation.type].find(
      (v) => v.year === newViolation.year,
    );

    if (dataToUpdate) {
      dataToUpdate.value = newViolation.violations;
    } else {
      console.log(`Year ${newViolation.year} not found in ${newViolation.type}`);
    }
  } else {
    console.log(`Type ${newViolation.type} not found in violation data`);
  }

  Object.keys(violationData).forEach((key) => {
    violationData[key].forEach((entry) => {
      if (parseInt(entry.year, 10) < firstInsuranceYear) {
        entry.value = 'NA';
      }
    });
  });

  const calculateTotal = (year, types) => {
    let sum = 0;
    let hasNA = false;

    types.forEach((type) => {
      const entry = violationData[type].find((v) => v.year === year);
      if (entry) {
        if (entry.value === 'NA') {
          hasNA = true;
        } else {
          sum += parseInt(entry.value, 10);
        }
      }
    });

    return hasNA ? 'NA' : sum.toString();
  };

  const fullTypes = Object.keys(violationData).filter((key) => key.startsWith('full') && key !== 'fullTotal');
  const partialTypes = Object.keys(violationData).filter((key) => key.startsWith('partial') && key !== 'partialTotal');

  violationData.fullTotal.forEach((entry) => {
    if (parseInt(entry.year, 10) >= firstInsuranceYear) {
      entry.value = calculateTotal(entry.year, fullTypes);
    } else {
      entry.value = 'NA';
    }
  });

  violationData.partialTotal.forEach((entry) => {
    if (parseInt(entry.year, 10) >= firstInsuranceYear) {
      entry.value = calculateTotal(entry.year, partialTypes);
    } else {
      entry.value = 'NA';
    }
  });

  updateFormData({
    violations_data: violationData,
  });

  setNewViolation({
    year: '',
    violations: '',
    type: '',
  });
};
