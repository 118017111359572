import styled from 'styled-components';

export const AutoCompletedScreenStyles = styled.div`
    .bg-secondary,
    .bg-primary {
    background-color: #00a651 !important;
  }
`;

export const ProfessionCompletedScreenStyles = styled.div`
 .bg-secondary,
    .bg-primary {
    background-color: #00a651 !important;
    }
  `;

export const TravelCompletedScreenStyles = styled.div`
 .bg-secondary,
    .bg-primary {
    background-color: #00a651 !important;
    }
  `;

export const TutelaLegaleCompletedScreenStyles = styled.div`
 .bg-secondary,
    .bg-primary {
    background-color: #00a651 !important;
    }
  `;

export const MotoCompletedScreenStyles = styled.div`
 .bg-secondary,
    .bg-primary {
    background-color: #00a651 !important;
    }
  `;
