import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import Joyride, { ACTIONS } from 'react-joyride';
import { PurchaseStyles } from './Purchase.styles';
import PageLoading from '../../common/components/elements/PageLoading';
import {
  purchaseInsuranceRequest, resetTravelPurchase, travelGetBackofficeUsers,
} from '../../features/purchases/purchasesActions';
import WaitingForPaymentStatus from '../../common/components/redesignComponents/Purchase/WaitingForPaymentStatus';
import InfoBoxTravel from '../../common/components/redesignComponents/Purchase/common/InfoBoxTravel';
import CompletedScreenTravel from '../../common/components/elements/PurchaseAuto/CompletedScreenTravel';
// import { useTourContext } from '../../context/useTourContext';

const statuses = [
  {
    id: 4,
    name: 'Attesa Pagamento',
  },
  {
    id: 6,
    name: 'In Attesa Emissione',
  },
  {
    id: 7,
    name: 'Polizza Emessa',
  },
];

function PurchaseTravelRequest() {
  const { requestToken } = useParams();
  const navigate = useNavigate();
  const state = useSelector((store) => store.purchases.travel);
  const {
    loading, quotation, insuranceRequest, quoteStatus, backofficeUsers, rc_quotation, tutela_quotation,
  } = state;
  // const [steps, setSteps] = useState();
  // const [showBeacon, setShowBeacon] = useState();

  const [supportMessage, setSupportMessage] = useState({
    subject: '',
    request_id: '',
    body: '',
    recipient: '',
  });

  const collectQuoteData = async () => {
    purchaseInsuranceRequest(requestToken, 'travel', setSupportMessage);
  };

  const navigateBack = () => {
    navigate(`/quotes/travel/${insuranceRequest.request_token}`);
  };

  useEffect(() => {
    const stepButtons = document.querySelectorAll('.step-button');
    const progress = document.querySelector('#progress');

    Array.from(stepButtons).forEach(() => {
      if (quoteStatus === 4) {
        progress.setAttribute('value', 0);
      } else if (quoteStatus === 6) {
        progress.setAttribute('value', 50);
      } else if (quoteStatus === 7) {
        progress.setAttribute('value', 100);
      }

      if (stepButtons[0] && quoteStatus === 1) {
        stepButtons[0].classList.add('done');
        stepButtons[1].classList.remove('done');
        stepButtons[2].classList.remove('done');
        stepButtons[0].ariaExpanded = true;
        stepButtons[1].ariaExpanded = false;
        stepButtons[2].ariaExpanded = false;
      }

      if (stepButtons[1] && quoteStatus === 6) {
        stepButtons[0].classList.add('done');
        stepButtons[1].classList.add('done');
        stepButtons[2].classList.remove('done');
        stepButtons[0].ariaExpanded = true;
        stepButtons[1].ariaExpanded = true;
        stepButtons[2].ariaExpanded = false;
      }

      if (stepButtons[1] && quoteStatus === 7) {
        stepButtons[0].classList.add('done');
        stepButtons[1].classList.add('done');
        stepButtons[2].classList.add('done');
        stepButtons[0].ariaExpanded = true;
        stepButtons[1].ariaExpanded = true;
        stepButtons[2].ariaExpanded = true;
      }
    });
  }, [quoteStatus]);

  useEffect(() => {
    collectQuoteData();
    travelGetBackofficeUsers();

    return () => {
      resetTravelPurchase();
    };
  }, []);

  // const {
  //   setState,
  //   state: { run },
  // } = useTourContext();

  // useEffect(() => {
  //   if (localStorage.getItem('purchaseTour') === 'done') {
  //     setState({ run: false });
  //   } else {
  //     setTimeout(() => {
  //       setState({ run: true });
  //     }, 1500);
  //   }
  // }, [localStorage.getItem('purchaseTour')]);

  // const handleCallback = (data) => {
  //   const {
  //     action,
  //     index,
  //     status,
  //     // step: {
  //     //   data: { next, previous },
  //     // },
  //     type,
  //   } = data;
  //   // const isPreviousAction = action === 'prev';

  //   if (type === 'step:after') {
  //     if (index === 3) {
  //       if (action === ACTIONS.NEXT) {
  //         localStorage.setItem('purchaseTour', 'done');
  //       }
  //     }

  //     if (action === ACTIONS.CLOSE) {
  //       localStorage.setItem('purchaseTour', 'done');
  //       setState({ run: false });
  //       setShowBeacon(false);
  //     }
  //   }

  //   if (status === 'skipped') {
  //     if (action === ACTIONS.SKIP) {
  //       localStorage.setItem('purchaseTour', 'done');
  //     }
  //   }
  // };

  // const firePurchaseTour = () => {
  //   localStorage.removeItem('purchaseTour');
  //   setState({ run: true });
  // };

  // useEffect(() => {
  //   if (quotation !== null) {
  //     if (quotation.status === 4) {
  //       setSteps(
  //         [
  //           {
  //             target: '.pagament-status-step-one',
  //             title: <h3 className="h5">Informazioni Quotazione e Cliente</h3>,
  //             content: (
  //               <p className="m-0">
  //                 In questa sezione troverete le informazioni per
  //                 {' '}
  //                 <b>cliente</b>
  //                 ,
  //                 {' '}
  //                 <b>prodotto</b>
  //                 ,
  //                 {' '}
  //                 <b>tipo prodotto</b>
  //                 ,
  //                 {' '}
  //                 <b>destinazione</b>
  //                 {' '}
  //                 e
  //                 {' '}
  //                 <b>numero persone che viaggiano</b>
  //                 .
  //               </p>
  //             ),
  //             disableBeacon: true,
  //             placement: 'right',
  //           },
  //           {
  //             target: '.pagament-status-step-two',
  //             title: <h3 className="h5">Informazioni Compagnia</h3>,
  //             content: (
  //               <p className="m-0">
  //                 In questa sezione troverete le informazioni sulla compagnia,
  //                 {' '}
  //                 <b>la data di partenza, lo stato del preventivo, le informazioni precontrattuali.</b>
  //               </p>
  //             ),
  //             placement: 'bottom',
  //           },
  //           {
  //             target: '.pagament-status-step-three',
  //             title: <h3 className="h5">Premio e Supporto</h3>,
  //             content: (
  //               <p className="m-0">
  //                 In questa sezione troverete il prezzo di quotazione e l'icona di
  //                 {' '}
  //                 <b>supporto</b>
  //                 . Se cliccate sull'icona potete iniziare una conversazione con
  //                 {' '}
  //                 <b>gli agenti del Backoffice di Greenia.</b>
  //               </p>
  //             ),
  //             placement: 'left',

  //           },
  //           {
  //             target: '.pagament-status-step-four',
  //             title: <h3 className="h5">Commissioni extra come intermediazione e servizi</h3>,
  //             content: (
  //               <p className="m-0">
  //                 In questa sezione vedrai i cursori dove puoi selezionare l'importo aggiunto al prezzo del preventivo. Queste commissioni aggiunte sono commissioni di intermediazione e servizi extra che l'intermediario può aggiungere.
  //               </p>
  //             ),
  //             placement: 'top',
  //           },
  //           {
  //             target: '.pagament-status-step-five',
  //             title: <h3 className="h5">Informazioni Cliente</h3>,
  //             content: (
  //               <p className="m-0">
  //                 In questa sezione puoi trovare le informazioni del cliente come
  //                 {' '}
  //                 <b>nome cognome o ragione sociale, telefono, email, indirizzo, frequenza di pagamento</b>
  //               </p>
  //             ),
  //             placement: 'top',
  //           },
  //           {
  //             target: '.pagament-status-step-six',
  //             title: <h3 className="h5">Informazioni Prodotto</h3>,
  //             content: (
  //               <p className="m-0">
  //                 In questa sezione puoi trovare informazioni sul preventivo come prezzo, importo pagato, importo di intermediazione e tipo di prodotto.
  //               </p>
  //             ),
  //             placement: 'top',
  //           },
  //           {
  //             target: '.pagament-status-step-seven',
  //             title: <h3 className="h5">Paga Con Carta</h3>,
  //             content: (
  //               <p className="m-0">
  //                 In questa sezione potrai procedere al pagamento con carta inserendo i dati della tua carta.
  //               </p>
  //             ),
  //             placement: 'top',
  //           },
  //           {
  //             target: '.pagament-status-step-eight',
  //             title: <h3 className="h5">Paga Con Bonifico</h3>,
  //             content: (
  //               <p className="m-0">
  //                 In questa sezione puoi procedere al pagamento dell'estratto conto bancario. Dopo aver effettuato il pagamento tramite banca, carica semplicemente l'estratto conto bancario affinché il nostro back office possa esaminarlo.
  //                 <hr />
  //                 Se sei un broker autorizzato con metodo di compensazione puoi anche usare il
  //                 {' '}
  //                 <b>Paga con compensazione</b>
  //                 . Ciò significa che riceverai una fattura settimanale per pagare tutti i preventivi che hai acquistato durante la settimana
  //               </p>
  //             ),
  //             placement: 'top',
  //           },
  //         ],
  //       );
  //     }

  //     if (quotation.status === 7) {
  //       setSteps(
  //         [
  //           {
  //             target: '.polizze-emessa-status-step-one',
  //             title: <h3 className="h5">Anagrafica</h3>,
  //             content: (
  //               <p className="m-0">
  //                 In questa sezione puoi trovare l'anagrafica del cliente
  //               </p>
  //             ),
  //             disableBeacon: true,
  //             placement: 'bottom',
  //           },
  //           {
  //             target: '.polizze-emessa-status-step-two',
  //             title: <h3 className="h5">Dati Prodotto Viaggi</h3>,
  //             content: (
  //               <p className="m-0">
  //                 In questa sezione puoi trovare i dati viaggio
  //               </p>
  //             ),
  //             placement: 'top',
  //           },
  //           {
  //             target: '.polizze-emessa-status-step-three',
  //             title: <h3 className="h5">Dati Polizza</h3>,
  //             content: (
  //               <p className="m-0">
  //                 In questa sezione puoi trovare i dati della polizza.
  //               </p>
  //             ),
  //             placement: 'bottom',
  //           },
  //           {
  //             target: '.polizze-emessa-status-step-four',
  //             title: <h3 className="h5">Documenti Della Polizza</h3>,
  //             content: (
  //               <p className="m-0">
  //                 In questa sezione puoi trovare i documenti del preventivo, puoi scaricarli e se hai documenti non firmati puoi caricare quelli firmati.
  //               </p>
  //             ),
  //             placement: 'top',
  //           },
  //         ],
  //       );
  //     }
  //   }
  // }, [quotation]);

  // const messages = {
  //   it: {
  //     back: 'Indietro',
  //     close: 'Chiudi',
  //     last: 'Fine',
  //     next: 'Prossima',
  //     open: 'Apri il modale',
  //     restart: 'Riavvia il tour ',
  //     skip: 'Salta',
  //   },
  //   en: {
  //     back: 'Back',
  //     close: 'Close',
  //     last: 'Last',
  //     next: 'Next',
  //     open: 'Open the dialog',
  //     restart: 'Restart the tour',
  //     skip: 'Skip',
  //   },
  // };

  // const options = {
  //   arrowColor: '#fff',
  //   backgroundColor: '#fff',
  //   beaconSize: 36,
  //   overlayColor: 'rgba(0, 0, 0, 0.5)',
  //   spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
  //   textColor: '#333',
  //   zIndex: 99999,
  // };

  if (loading) {
    return <PageLoading />;
  }

  return (
    <PurchaseStyles>
      {/* <Joyride
        callback={handleCallback}
        continuous
        run={run}
        steps={steps}
        showBeacon={showBeacon}
        styles={{
          options,

          buttonNext: {
            backgroundColor: '#00a651',
            outline: 'none',
          },
          buttonBack: {
            color: '#00a651',
            outline: 'none',
          },
          beaconInner: {
            backgroundColor: '#00a651',
          },
          beaconOuter: {
            borderColor: '#00a651',
            backgroundColor: 'rgba(68 , 154 , 71, 0.4)',
          },
          buttonSkip: {
            outline: 'none',
          },
          buttonClose: {
            outline: 'none',
          },
        }}
        showProgress
        locale={messages.it}
        showSkipButton
      /> */}
      {quoteStatus !== 7
        && (
          <>
            <div className="container mt-0 gap-2 align-items-center justify-content-start mb-3">
              <div className="px-2 py-1 d-flex align-items-center gap-2">
                <div className="cursor-pointer d-flex align-items-center justify-content-start gap-2 fw-bold text-success" onClick={() => navigateBack()}>
                  <div id="back-button">
                    <FontAwesomeIcon icon={faArrowLeftLong} />
                  </div>
                  <p className="ml-2 mb-0  cursor-pointer">Modifica Preventivo</p>
                </div>

                {/* <div>
                  {localStorage.getItem('purchaseTour') === 'done' && (
                    <div style={{ cursor: 'pointer' }} onClick={() => firePurchaseTour()}>
                      <button className="btn btn-success px-2 py-1">Tutorial</button>
                    </div>
                  )}
                </div> */}
              </div>
              {/* <TravelStepper quoteStatus={quoteStatus} /> */}
              <InfoBoxTravel
                quote={quotation}
                rc_quotation={rc_quotation}
                tutela_quotation={tutela_quotation}
                insuranceRequest={insuranceRequest}
                statues={statuses}
                quoteStatus={quoteStatus}
                messagesModalData={{
                  backofficeUsers, setSupportMessage, supportMessage, collectData: collectQuoteData,
                }}
              />
            </div>
            <div className="container mt-0">

              {quoteStatus === 4
                && (
                  <WaitingForPaymentStatus
                    quote={quotation}
                    insuranceRequest={insuranceRequest}
                    collectQuoteData={collectQuoteData}
                    assistenza_quotation={null}
                    infortuni_conducente_quotation={null}
                    rivalsa_quotation={null}
                    cristalli_quotation={null}
                    rc_quotation={rc_quotation}
                    tutela_quotation={tutela_quotation}
                  />
                )}

              {quoteStatus === 6
                && (
                  <div className="card-body mt-4">
                    <div className="row">
                      <div className="col-6 offset-3">
                        <div
                          className="alert alert-success"
                          role="alert"
                        >
                          <h4 className="alert-heading">
                            In Attesa Emissione
                          </h4>
                          <p>
                            Quando l'agente di backoffice carica la
                            polizza, riceverai una notifica e il
                            contratto potrà essere scaricato.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
            </div>
          </>
        )}

      {quoteStatus === 7
        && (
          <>
            <div className="mt-0 gap-2 align-items-center justify-content-start mb-3">
              {/* <div>
                {localStorage.getItem('purchaseTour') === 'done' && (
                  <div style={{ cursor: 'pointer' }} onClick={() => firePurchaseTour()}>
                    <button className="btn btn-success px-2 py-1">Tutorial</button>
                  </div>
                )}
              </div> */}
            </div>
            {/* <CompletedQuoteScreenTravel quotation={quotation} insuranceRequest={insuranceRequest} downloadDocument={downloadDocument} quoteStatus={quoteStatus} collectQuoteData={collectQuoteData} rc_quotation={rc_quotation} tutela_quotation={tutela_quotation} /> */}

            <CompletedScreenTravel
              insuranceRequest={insuranceRequest}
              quoteStatus={quoteStatus}
              rc_quotation={rc_quotation}
            />
          </>
        )}

    </PurchaseStyles>
  );
}

export default PurchaseTravelRequest;
