import React from 'react';
import {
  BoxWrapper, ClientBox,
} from './CommonPurchase.styles';
import { guideType, primaQuoteTypes } from '../../../../constants';
import MessagesModal from '../../../elements/MessagesModal';
import { downloadFile } from '../../../../../services/axios-client/axeHelpers';
import axe from '../../../../../services/axios-client/axiosClient';

function InfoBoxAuto({
  insuranceRequest, statues, quoteStatus, messagesModalData, rc_quotation, tutela_quotation, assistenza_quotation, rivalsa_quotation,
  cristalli_quotation, infortuni_conducente_quotation,
}) {
  const downloadResourceDocs = async (id) => {
    const res = await axe.get(`/download-resource-document/${id}/0`, {
      responseType: 'blob',
    });

    downloadFile(res.data);
  };

  const downloadPreventive = async () => {
    const res = await axe.get(
      `/quotations/${rc_quotation.id}/preventivo/generate`,
      {
        responseType: 'blob',
      },
    );

    downloadFile(res.data);
  };

  const getTotal = () => {
    let rcTotalAmount = 0;
    let tutelaTotalAmount = 0;
    let assistenzaInProccessAmount = 0;
    let rivalsaInProccessAmount = 0;
    let cristalliInProccessAmount = 0;
    let infortuniConducenteInProccessAmount = 0;
    let primaGuaranteesPrices = 0;

    if (rc_quotation.company_id === 2) {
      rc_quotation.guarantees.forEach((guarantee) => {
        primaGuaranteesPrices += parseFloat(guarantee.amount) || 0;
      });
    }

    rcTotalAmount = rc_quotation.amount;
    tutelaTotalAmount = tutela_quotation !== null ? tutela_quotation.amount : 0;
    assistenzaInProccessAmount = assistenza_quotation !== null ? assistenza_quotation.amount : 0;
    rivalsaInProccessAmount = rivalsa_quotation !== null ? rivalsa_quotation.amount : 0;
    cristalliInProccessAmount = cristalli_quotation !== null ? cristalli_quotation.amount : 0;
    infortuniConducenteInProccessAmount = infortuni_conducente_quotation !== null ? infortuni_conducente_quotation.amount : 0;

    return parseFloat(rcTotalAmount + tutelaTotalAmount + assistenzaInProccessAmount + rivalsaInProccessAmount + cristalliInProccessAmount + infortuniConducenteInProccessAmount + primaGuaranteesPrices).toFixed(2);
  };

  return (
    <>
      <BoxWrapper>
        <div className="row gap-2 gap-xl-0">
          <div className="col-12 col-xl-4">
            <ClientBox className="documents-status-step-one pagament-status-step-one">
              <h6>Cliente:</h6>
              <h5>
                {insuranceRequest.customer.gender !== 'G' ? `${insuranceRequest.customer.name} ${insuranceRequest.customer.surname}` : insuranceRequest.customer.business_name}
              </h5>
              <h6 className="mt-2">Veicolo:</h6>
              <h5>
                {insuranceRequest.vehicle.version.full_description}
              </h5>
              <h6 className="mt-2">Targa:</h6>
              <h5>
                {insuranceRequest.vehicle.vehicle_plate}
              </h5>
              <h6 className="mt-2">Decorrenza:</h6>
              <h5>
                {new Date(
                  insuranceRequest.policy_effective_date,
                ).toLocaleDateString('it-IT')}
              </h5>
              <h6 className="mt-2">Tipo Di Guida:</h6>
              <h5>
                {guideType.find((v) => v.id === insuranceRequest.guide_type).name}
              </h5>
              <h6 className="mt-2">Status:</h6>
              <h5>
                {statues.find((st) => st.id === quoteStatus).name}
              </h5>
              <h6
                className="mt-2 text-decoration-underline"
                style={{ cursor: 'pointer' }}
                onClick={() => (
                  rc_quotation.company_id === 1 ? downloadResourceDocs(rc_quotation.product_id == 1 ? 1 : 5, 0)
                    : downloadResourceDocs(rc_quotation.product_id == 1 ? 6 : 7, 0))}
              >
                Informazioni Precontrattuali
              </h6>
              <h6 className="mt-2 text-decoration-underline" onClick={() => downloadPreventive()} style={{ cursor: 'pointer' }}>Preventivo</h6>
            </ClientBox>
          </div>
          <div className="col-12 col-xl-8">
            <ClientBox className="documents-status-step-two pagament-status-step-two">
              <h4>Informazioni Prodotti</h4>
              <hr />
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Prodotto</th>
                    <th scope="col">Compagnia</th>
                    <th scope="col">Frequenza Pagamento</th>
                    <th scope="col">Premio</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{rc_quotation.product.name}</td>
                    <td>
                      {rc_quotation.company_id !== null ? rc_quotation.company.name : rc_quotation.preventivas_company.company_name}
                    </td>
                    <td>{rc_quotation.payment_frequency === 1 ? 'Annuale' : 'Semestrale'}</td>
                    <td>
                      {rc_quotation.company_id === 2 ? rc_quotation.quotation_data_prima.quotation_data_prima_guarantees.find((price) => price.slug === 'rca').quotation_data_prima_guarantee_prices[0].discounted_to_display : parseFloat(rc_quotation.amount)}
                      {' '}
                      &euro;
                    </td>
                  </tr>
                  {(rc_quotation !== null && rc_quotation.company_id === 2) && (
                    rc_quotation.guarantees.map((guarantee, index) => (
                      <tr key={index}>
                        <td>{primaQuoteTypes.find((type) => type.id === guarantee.guarantees_slug).name}</td>
                        <td>
                          Prima.it
                        </td>
                        <td>Annuale</td>
                        <td>
                          {' '}
                          {parseFloat(guarantee.amount)}
                          {' '}
                          €
                        </td>
                      </tr>
                    ))
                  )}
                  {tutela_quotation !== null && (
                    <tr>
                      <td>Tutela Legale</td>
                      <td>
                        {tutela_quotation.company.name}
                      </td>
                      <td>{tutela_quotation.payment_frequency === 1 ? 'Annuale' : 'Semestrale'}</td>
                      <td>
                        {parseFloat(tutela_quotation.amount)}
                        {' '}
                        &euro;
                      </td>
                    </tr>
                  )}
                  {assistenza_quotation !== null && (
                    <tr>
                      <td>Assistenza Stradale</td>
                      <td>
                        {assistenza_quotation && assistenza_quotation.company.name}
                      </td>
                      <td>{assistenza_quotation.payment_frequency === 1 ? 'Annuale' : 'Semestrale'}</td>
                      <td>
                        {parseFloat(assistenza_quotation.amount)}
                        {' '}
                        &euro;
                      </td>
                    </tr>
                  )}
                  {infortuni_conducente_quotation !== null && (
                    <tr>
                      <td>Infortuni Conducente</td>
                      <td>
                        {infortuni_conducente_quotation.company.name}
                      </td>
                      <td>{infortuni_conducente_quotation.payment_frequency === 1 ? 'Annuale' : 'Semestrale'}</td>
                      <td>
                        {parseFloat(infortuni_conducente_quotation.amount)}
                        {' '}
                        &euro;
                      </td>
                    </tr>
                  )}
                  {cristalli_quotation !== null && (
                    <tr>
                      <td>Cristalli</td>
                      <td>
                        {' '}
                        {cristalli_quotation.company.name}
                      </td>
                      <td>{cristalli_quotation.payment_frequency === 1 ? 'Annuale' : 'Semestrale'}</td>
                      <td>
                        {parseFloat(cristalli_quotation.amount)}
                        {' '}
                        &euro;
                      </td>
                    </tr>
                  )}
                  {rivalsa_quotation !== null && (
                    <tr>
                      <td>Rivalsa</td>
                      <td>
                        {' '}
                        {rivalsa_quotation.company.name}
                      </td>
                      <td>{rivalsa_quotation.payment_frequency === 1 ? 'Annuale' : 'Semestrale'}</td>
                      <td>
                        {parseFloat(rivalsa_quotation.amount)}
                        {' '}
                        &euro;
                      </td>
                    </tr>
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <td className="fw-bold">
                      Totale
                    </td>
                    <td />
                    <td />
                    <td className="fw-bold">
                      &euro;
                      {' '}
                      {getTotal()}
                    </td>
                  </tr>
                </tfoot>
              </table>
              {/* <hr />
              <ContactBox
                as="button"
                data-bs-toggle="modal"
                data-bs-target="#messages-modal"
              >
                <img src={contactSupport} alt="Conttato Suporto" />
                {insuranceRequest.threads.length > 0 && insuranceRequest.threads[0].messages.filter((mes) => mes.read_by_broker === 0).length > 0 ? <MessageNumber>{insuranceRequest.threads[0].messages.filter((mes) => mes.read_by_broker === 0).length}</MessageNumber> : ''}
                <h5>{insuranceRequest.threads.length > 0 ? 'Apri Chat' : 'Apri Nuova Chat'}</h5>
              </ContactBox> */}
            </ClientBox>
          </div>
        </div>

        {/* <ClientBox className="justify-content-between documents-status-step-three pagament-status-step-three">
          <div>
            {rc_quotation.amount_changed && <div className="badge bg-success">Attenzione! Premio e Stato Aggiornato</div>}
            <h6 className="mt-2 fs-6">Premio Totale</h6>
            <h5 className="fs-5">
              &euro;
              {' '}
              {getTotal()}
            </h5>
          </div>
          <ContactBox
            as="button"
            data-bs-toggle="modal"
            data-bs-target="#messages-modal"
          >
            <img src={contactSupport} alt="Conttato Suporto" />
            {insuranceRequest.threads.length > 0 && insuranceRequest.threads[0].messages.filter((mes) => mes.read_by_broker === 0).length > 0 ? <MessageNumber>{insuranceRequest.threads[0].messages.filter((mes) => mes.read_by_broker === 0).length}</MessageNumber> : ''}
            <h5>{insuranceRequest.threads.length > 0 ? 'Apri Chat' : 'Apri Nuova Chat'}</h5>
          </ContactBox>
        </ClientBox> */}
      </BoxWrapper>
      <MessagesModal
        {...messagesModalData}
        threads={insuranceRequest.threads}
      />
    </>
  );
}

export default InfoBoxAuto;
