import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// import Joyride from 'react-joyride';
import { useSelector } from 'react-redux';
import { listQuotationsBeingWorkedRequests, resetQuotationsBeingWorkedRequests } from '../features/quotations/quotationsActions';
import { generalAlertError } from '../common/utils/alerts';
import { PurchasedQuotationsStyles } from './PurchasedQuotations/PurchasedQuotations.styles';
import Pagination from '../common/components/elements/Pagination';
// import { useTourContext } from '../context/useTourContext';
import ErrorComponent from '../common/components/ErrorComponent';
// import { handleCallbackQuoteInProgress } from '../common/utils/joyrideCallbacks';
// import { quotationsInProgressSteps } from '../common/utils/joyrideSteps';

function QuotationsInProgress() {
  const state = useSelector((store) => store.quotations.quotations_being_worked);

  const {
    quotations: quotesInProgress, loading, page_number, total_pages, error,
  } = state;

  const [search, setSearch] = useState({
    search: '',
    plate: '',
    date_from: '',
    date_to: '',
  });
  // const [steps, setSteps] = useState([]);

  // const {
  //   setState,
  //   state: { run, showBeacon },
  // } = useTourContext();

  // useEffect(() => {
  //   if (localStorage.getItem('quotazioniInProcesso') === 'done') {
  //     setState({ run: false });
  //   } else if (loading === false) {
  //     setTimeout(() => {
  //       setState({ run: true });
  //     }, 3000);
  //   }
  // }, [localStorage.getItem('quotazioniInProcesso'), loading]);

  // const fireNewQuoteTour = () => {
  //   localStorage.removeItem('quotazioniInProcesso');
  //   setState({ run: true, tourActive: true });
  // };

  // const options = {
  //   arrowColor: '#fff',
  //   backgroundColor: '#fff',
  //   beaconSize: 36,
  //   overlayColor: 'rgba(0, 0, 0, 0.5)',
  //   spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
  //   textColor: '#333',
  //   zIndex: 99999,
  // };
  const getQuotes = async () => {
    listQuotationsBeingWorkedRequests();
  };

  const handleChange = ({ target: { value, name } }) => {
    setSearch({ ...search, [name]: value });
  };

  const searchHandler = (e) => {
    e.preventDefault();

    if (search.date_from !== '' && search.date_to === '') {
      generalAlertError('Devi selezionare anche data al');
      return;
    }

    if (search.date_from === '' && search.date_to !== '') {
      generalAlertError('Devi selezionare anche data da');
      return;
    }

    listQuotationsBeingWorkedRequests(1, search);
  };

  useEffect(() => {
    getQuotes();

    return () => {
      resetQuotationsBeingWorkedRequests();
    };
  }, []);

  // useEffect(() => {
  //   quotationsInProgressSteps(quotesInProgress, setSteps);
  // }, [quotesInProgress]);

  return error === null ? (
    <PurchasedQuotationsStyles>
      {/* <Joyride
        steps={steps}
        continuous
        showBeacon={showBeacon}
        locale={{
          back: 'Indietro',
          close: 'Chiudi',
          last: 'Fine',
          next: 'Prossima',
          open: 'Apri il modale',
          restart: 'Riavvia il tour ',
          skip: 'Salta',
        }}
        styles={{
          options,

          buttonNext: {
            backgroundColor: '#00a651',
            outline: 'none',
          },
          buttonBack: {
            color: '#00a651',
            outline: 'none',
          },
          beaconInner: {
            backgroundColor: '#00a651',
          },
          beaconOuter: {
            borderColor: '#00a651',
            backgroundColor: 'rgba(68 , 154 , 71, 0.4)',
          },
          buttonSkip: {
            outline: 'none',
          },
          buttonClose: {
            outline: 'none',
          },
        }}
        run={run}
        callback={(data) => handleCallbackQuoteInProgress(data, steps, setState)}
        showSkipButton
        showProgress
      /> */}
      <h5 className="mb-4">Lista Quotazioni In Processo</h5>
      {/* {localStorage.getItem('quotazioniInProcesso') === 'done' && (
        <div style={{ cursor: 'pointer', top: '8%' }} onClick={() => fireNewQuoteTour()} className="position-fixed end-0 mt-3 me-3">
          <button className="btn btn-success px-2 py-1">Tutorial</button>
        </div>
      )} */}
      <div className="d-flex flex-md-row flex-column-reverse">
        <div className="col-xl-8 col-md-7 col-lg-8">
          <div className="mt-3 quotazioni-in-processo-one">
            {loading
              ? (
                <div className="d-flex justify-content-center align-items-center flex-column">
                  <div className="spinner-border" role="status" />
                  <span>Attendere prego...</span>
                </div>
              )
              : (
                <ul className="list-group">
                  {quotesInProgress.length > 0
                    ? quotesInProgress.map((quote, index) => (
                      <li key={index} className="quotazioni-in-processo-two list-group-item d-flex flex-xl-row flex-column justify-content-between align-items-start align-items-xl-center">
                        <div className="ms-0 me-auto mt-1 mt-sm-0 mb-0">
                          {quote.insurance_request.customer && (
                            <div className="fw-bold">
                              Cliente:
                              {' '}
                              {quote.insurance_request.customer.gender === 'G' ? quote.insurance_request.customer.business_name : `${quote.insurance_request.customer.name} ${quote.insurance_request.customer.surname}`}
                            </div>
                          )}
                          {quote.insurance_request.product
                            && (
                              <>
                                Prodotto:
                                {' '}
                                {quote.insurance_request.product.name}
                                {' '}
                                {quote.insurance_request.product_id == 5 ? `(${quote.insurance_request.infortuni && quote.insurance_request.infortuni.company})` : ''}
                              </>
                            )}
                          {quote.insurance_request.vehicle
                            && (
                              <>
                                <br />
                                Targa:
                                {' '}
                                {quote.insurance_request.vehicle.vehicle_plate}
                              </>
                            )}
                          {quote.insurance_request.professional
                            && (
                              <>
                                <br />
                                Professione:
                                {' '}
                                {quote.insurance_request.professional.profession_desc}
                              </>
                            )}
                          {quote.insurance_request.infortuni
                            && (
                              <>
                                <br />
                                Infortuni:
                                {' '}
                                {quote.insurance_request.infortuni.pack_type}
                                {' '}
                                -
                                {quote.insurance_request.infortuni.name}
                              </>
                            )}
                          <br />
                          <small>
                            <strong>
                              Creato:
                              {new Date(quote.created_at).toLocaleDateString('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                            </strong>
                          </small>
                        </div>

                        <div className="me-4 my-2">
                          <span className="badge text-bg-dark">
                            {quote.quotation_status.name}
                          </span>
                        </div>

                        {quote.product_id == 1
                          && (
                            <Link to={`/quotes/auto/purchase/${quote.request_token}`}>
                              {quote.insurance_request.is_expired === true ? (
                                <span className="badge btn-to-quote-expired px-4 py-2 rounded-3">
                                  Preventivo Scaduto
                                </span>
                              ) : (
                                <span className="badge btn-to-quote px-4 py-2 rounded-3">
                                  Vai al preventivo
                                </span>
                              )}
                            </Link>
                          )}

                        {quote.product_id == 2
                          && (
                            <Link to={`/quotes/moto/purchase/${quote.request_token}`}>
                              {quote.insurance_request.is_expired === true ? (
                                <span className="badge btn-to-quote-expired px-4 py-2 rounded-3">
                                  Preventivo Scaduto
                                </span>
                              ) : (
                                <span className="badge btn-to-quote px-4 py-2 rounded-3">
                                  Vai al preventivo
                                </span>
                              )}
                            </Link>
                          )}

                        {quote.product_id == 3
                          && (
                            <Link to={`/quotes/autocarro/purchase/${quote.request_token}`}>
                              {quote.insurance_request.is_expired === true ? (
                                <span className="badge btn-to-quote-expired px-4 py-2 rounded-3">
                                  Preventivo Scaduto
                                </span>
                              ) : (
                                <span className="badge btn-to-quote px-4 py-2 rounded-3">
                                  Vai al preventivo
                                </span>
                              )}
                            </Link>
                          )}

                        {quote.product_id == 5
                          && (
                            <Link to={`/quotes/metlife/purchase/${quote.request_token}`}>
                              {quote.insurance_request.is_expired === true ? (
                                <span className="badge btn-to-quote-expired px-4 py-2 rounded-3">
                                  Preventivo Scaduto
                                </span>
                              ) : (
                                <span className="badge btn-to-quote px-4 py-2 rounded-3">
                                  Vai al preventivo
                                </span>
                              )}
                            </Link>
                          )}
                        {quote.product_id == 4
                          && (
                            <Link to={`/quotes/profession/purchase/${quote.request_token}`}>
                              {quote.insurance_request.is_expired === true ? (
                                <span className="badge btn-to-quote-expired px-4 py-2 rounded-3">
                                  Preventivo Scaduto
                                </span>
                              ) : (
                                <span className="badge btn-to-quote px-4 py-2 rounded-3">
                                  Vai al preventivo
                                </span>
                              )}
                            </Link>
                          )}
                        {quote.product_id == 6
                          && (
                            <Link to={`/quotes/infodrive/purchase/${quote.request_token}`}>
                              {quote.insurance_request.is_expired === true ? (
                                <span className="badge btn-to-quote-expired px-4 py-2 rounded-3">
                                  Preventivo Scaduto
                                </span>
                              ) : (
                                <span className="badge btn-to-quote px-4 py-2 rounded-3">
                                  Vai al preventivo
                                </span>
                              )}
                            </Link>
                          )}
                        {quote.product_id == 8
                          && (
                            <Link to={`/quotes/travel/purchase/${quote.request_token}`}>
                              {quote.insurance_request.is_expired === true ? (
                                <span className="badge btn-to-quote-expired px-4 py-2 rounded-3">
                                  Preventivo Scaduto
                                </span>
                              ) : (
                                <span className="badge btn-to-quote px-4 py-2 rounded-3">
                                  Vai al preventivo
                                </span>
                              )}
                            </Link>
                          )}

                        {quote.product_id == 9
                          && (
                            <Link to={`/quotes/tutela-legale/purchase/${quote.request_token}`}>
                              {quote.insurance_request.is_expired === true ? (
                                <span className="badge btn-to-quote-expired px-4 py-2 rounded-3">
                                  Preventivo Scaduto
                                </span>
                              ) : (
                                <span className="badge btn-to-quote px-4 py-2 rounded-3">
                                  Vai al preventivo
                                </span>
                              )}
                            </Link>
                          )}

                      </li>
                    ))
                    : (
                      <li className="list-group-item d-flex justify-content-between align-items-start quotes-in-progress-no-result">
                        <div className="ms-2 me-auto">
                          <div className="fw-bold">
                            Nessuna quotazione trovata!
                          </div>
                        </div>
                      </li>
                    )}
                </ul>
              )}
            <div className="mt-2">
              <Pagination total_pages={total_pages} current_page={page_number} onClick={listQuotationsBeingWorkedRequests} search={search} />
            </div>
          </div>

        </div>
        <div className="col-lg-3 col-md-5 col-xl-3 p-3 quotation-filters quotazioni-in-processo-three">
          <form onSubmit={searchHandler} className="d-flex align-items-end justify-content-center flex-column">

            <input type="text" name="search" className="search-input form-control mb-2" value={search.search} onChange={handleChange} placeholder="Cerca quotazioni..." />
            <input type="text" name="plate" className="search-plate form-control mb-2" value={search.plate} onChange={handleChange} placeholder="Ricerca targa..." />
            <div className="w-100">
              <label htmlFor="date_from">Data creazione da</label>
              <input type="date" name="date_from" onChange={handleChange} value={search.date_from} className="date-input form-control mb-2" placeholder="Data creazione da" />
            </div>
            <div className="w-100">
              <label htmlFor="date_to">Data creazione al</label>
              <input type="date" name="date_to" className="date-input form-control mb-0" onChange={handleChange} value={search.date_to} min={search.date_from} placeholder="Data creazione al" />
            </div>
            <button type="submit" className="search-btn mt-2">
              Cerca
            </button>
          </form>
        </div>
      </div>
    </PurchasedQuotationsStyles>
  ) : <ErrorComponent error={error} />;
}

export default QuotationsInProgress;
