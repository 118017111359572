import React from 'react';
import {
  LeftCol, LogoPriceContainer, PriceContainer, RightCol,
} from './AdriaticCard.styles';
import {
  LogoResultContainer, PriceResult, QuoteInformationResultContainer, QuoteInformationResultItem,
} from '../../../../screens/BackOffice/results/cards/PrimaCard.styles';
import { TutelaLegaleCardStyles } from './TutelaLegaleCard.styles';
import { BACKEND_BASE_URL } from '../../../../config';

function TutelaLegaleCardResult({ quote }) {
  return (
    <TutelaLegaleCardStyles className={quote.is_aquista === 1 ? 'selected' : ''}>
      <div className="row-container">
        <LeftCol>
          <LogoPriceContainer>
            <LogoResultContainer>
              <img src={BACKEND_BASE_URL + quote.company.logo} alt={`${quote.company.name} Logo`} />
            </LogoResultContainer>
            <PriceContainer>
              <PriceResult>
                <h5>
                  Prezzo annuo:
                </h5>
                <h2>
                  &euro;
                  {' '}
                  {quote.amount.toFixed(2).toLocaleString()}
                </h2>
              </PriceResult>
            </PriceContainer>
          </LogoPriceContainer>
          <QuoteInformationResultContainer>
            <QuoteInformationResultItem>
              <h6>
                Compagnia:
              </h6>
              <p>
                {quote.company.name}
              </p>
            </QuoteInformationResultItem>
            <QuoteInformationResultItem>
              <h6>
                Prodotto:
              </h6>
              <p>
                Tutela Legale
              </p>
            </QuoteInformationResultItem>
          </QuoteInformationResultContainer>
          <QuoteInformationResultContainer className="ms-3 ms-sm-0">
            <QuoteInformationResultItem>
              <h6>
                Franchigia:
              </h6>
              <p>
                1,000&euro;
              </p>
            </QuoteInformationResultItem>
            <QuoteInformationResultItem>
              <h6>
                Tempo medio di emissione:
              </h6>
              <p>
                2 giorni
              </p>
            </QuoteInformationResultItem>
          </QuoteInformationResultContainer>
        </LeftCol>
        <RightCol />
      </div>
    </TutelaLegaleCardStyles>
  );
}

export default TutelaLegaleCardResult;
