import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { TravelCompletedScreenStyles } from './completedScreens.styles';
import { generalAlertError, generalAlertSuccessToast } from '../../common/utils/alerts';
import { apiDownloadDocument, documentMethodManualUpload } from '../../services/axios-client/axeCommons';
import quotationsAxe from '../../services/axios-client/quotationsAxeClient';
import { getTravelCompletedData, travelCompletedDataReset } from '../../features/purchases/purchasesActions';
import PageLoading from '../../common/components/elements/PageLoading';
import ErrorComponent from '../../common/components/ErrorComponent';
import { productsTravel } from '../../common/constants';
import { ActionsWrapperResultPage } from '../../common/components/redesignComponents/Purchase/SignatureType.styles';
import pdfIMG from '../../assets/images/purchase/pdf.png';
import downloadIMG from '../../assets/redesign_icons/download.svg';
import uploadIcon from '../../assets/redesign_icons/upload.svg';

function TravelCompletedScreen() {
  const { quoteId } = useParams();
  const {
    loading, quote, error, insurance_request,
  } = useSelector((store) => store.purchases.travel_completed);
  const [residenceInfo, setResidenceInfo] = useState(null);
  const uploadPolizzaEmessa = useRef(null); // 13 - Polizza emessa
  const uploadQuietanzaSemestrale = useRef(null); // 32 - Quietanza Semestrale
  const uploadPrivacyRef = useRef(null); // 12 - Privacy Greenia
  const uploadPreContractRef = useRef(null); // 29 - Percontrattuale Greenia
  const uploadDichiarazioniDocRef = useRef(null); // 28 - Dichiarazioni del contraente
  const uploadQuestionarioAutoDocRef = useRef(null); // 30 - Questionario Auto

  const collectQuoteData = () => {
    getTravelCompletedData(quoteId);
  };

  const collectMunicipalities = () => {
    try {
      quotationsAxe.get('/form-data/communes').then((res) => {
        const commune = res.data.data.find((commune) => commune.cadastral_code == insurance_request.customer.residence_commune_code);
        setResidenceInfo(commune);
      });
    } catch (err) {
      // Do nothing
    }
  };

  const downloadDocument = async (documentId) => {
    const response = await apiDownloadDocument(documentId);
    const alink = document.createElement('a');
    alink.target = '_blank';
    alink.href = response.data.data.contents;
    alink.download = response.data.data.filename;
    alink.click();
  };

  useEffect(() => {
    if (quote && quote.status === 7) {
      collectMunicipalities();
    }
  }, [quote]);

  const fireSignedDocUpload = (typeId) => {
    if (typeId === 13) {
      uploadPolizzaEmessa.current.click();
    }
    if (typeId === 32) {
      uploadQuietanzaSemestrale.current.click();
    }
    if (typeId === 29) {
      uploadPreContractRef.current.click();
    }

    if (typeId === 12) {
      uploadPrivacyRef.current.click();
    }

    if (typeId === 28) {
      uploadDichiarazioniDocRef.current.click();
    }

    if (typeId === 30) {
      uploadQuestionarioAutoDocRef.current.click();
    }
  };

  const uploadSignedDocument = async (documentId, files) => {
    const uploadedFile = files[0];
    const reader = new FileReader();

    reader.readAsDataURL(uploadedFile);
    if (uploadedFile.type !== 'application/pdf') {
      generalAlertError('Formato di file errato. Il file deve essere in .pdf');
      return;
    }
    reader.onload = () => {
      documentMethodManualUpload(quote.id, documentId, {
        document: reader.result.replace('data:application/pdf;base64,', ''),
      }).then(() => {
        collectQuoteData();
        generalAlertSuccessToast('File caricato');
      });
    };
  };

  useEffect(() => {
    collectQuoteData();

    return () => {
      travelCompletedDataReset();
    };
  }, [quoteId]);

  if (loading) {
    return <PageLoading />;
  }

  if (loading === false && error !== null) {
    return <ErrorComponent error={error} />;
  }

  return (
    <TravelCompletedScreenStyles className="row">
      <div className="col-xl-6 ">
        <div className="card border mb-2 polizze-emessa-status-step-one">
          <div className="card-header bg-secondary text-white">
            <h2 className="h3 mb-0 card-title">
              Anagrafica
            </h2>
          </div>
          <div className="card-body ">
            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                <b>Nome e Cognome:</b>
                {' '}
                {insurance_request.customer.name}
                {' '}
                {insurance_request.customer.surname}
              </li>
              <li className="list-group-item">
                <b>Email:</b>
                {' '}
                {insurance_request.customer.email}
              </li>
              <li className="list-group-item">
                <b>Telefono:</b>
                {' '}
                {insurance_request.customer.phone}
              </li>
              <li className="list-group-item">
                <b>Indirizo:</b>
                {' '}
                {insurance_request.customer.address}
                ,
                {' '}
                {insurance_request.customer.house_number}
                ,
                {' '}
                {insurance_request.customer.postal_code}
              </li>
              <li className="list-group-item">
                <b>Residenza:</b>
                {' '}
                {residenceInfo !== null && residenceInfo.name}
              </li>
              <li className="list-group-item">
                <b>Citta:</b>
                {' '}
                {residenceInfo !== null && residenceInfo.communal_territory.name}
              </li>
            </ul>
          </div>
        </div>
        <div className="card border polizze-emessa-status-step-two">
          <div className="card-header bg-secondary text-white">
            <h2 className="h3 mb-0 card-title">
              Dati Prodotto Viaggi
            </h2>
          </div>
          <div className="card-body">
            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                <b>Data partenza:</b>
                {' '}
                {moment(insurance_request.policy_effective_data).format('DD-MM-YYYY')}
              </li>
              <li className="list-group-item">
                <b>Data Ritorno:</b>
                {' '}
                {moment(insurance_request.insurance_request_travel.end_date).format('DD-MM-YYYY')}
              </li>
              <li className="list-group-item">
                <b>Destinazione:</b>
                {' '}
                {insurance_request.insurance_request_travel.country.name}
              </li>
              <li className="list-group-item">
                <b>Numero di persone:</b>
                {' '}
                {1 + insurance_request.insurance_request_travel.insurance_request_travel_people.length}
              </li>

            </ul>
          </div>
        </div>
      </div>
      <div className="col-xl-6">
        <div className="card border mb-2 polizze-emessa-status-step-three">
          <div className="card-header bg-secondary text-white">
            <h2 className="h3 mb-0 card-title">
              Dati Polizza
            </h2>
          </div>
          <div className="card-body">
            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                <b>Compagnia: </b>
                {' '}
                {quote.company.name}
              </li>
              <li className="list-group-item">
                <b>Prodotto: </b>
                {' '}
                {productsTravel[quote.quotation_data_travel_europeassistance.product_code]}
              </li>
              <li className="list-group-item">
                <b>Stato:</b>
                {' '}
                {quote.status === 7 && 'Polizza Emmesa'}
              </li>
              <li className="list-group-item">
                <b>Polizza Pagata:</b>
                {' '}
                SI
              </li>
              <li className="list-group-item">
                <b>Totale:</b>
                {' '}
                {quote.amount.toLocaleString()}
                {' '}
                &euro;
              </li>
            </ul>
          </div>

        </div>
        <div className="card border polizze-emessa-status-step-four">
          <div className="card-header bg-secondary text-white">
            <h2 className="h3 mb-0 card-title">
              Documenti Polizza
            </h2>
          </div>
          <div className="card-body">
            <ol className="list-group list-group-numbered">
              {insurance_request.documents.map((document, index) => (
                <li className="list-group-item d-flex justify-content-between align-items-center" key={index}>
                  <div className="ms-2 me-auto">
                    <div className="fw-bold mb-2">{document.document_type.name}</div>
                    <div className="d-flex align-items-center justify-content-between">
                      <img src={pdfIMG} className="img-fluid" width="25" alt="PDF" />
                      <div className="mx-2">
                        <h5 className="h6 mb-0">{document.original_filename}</h5>
                      </div>
                      <div className="d-flex align-items-center justify-content-center">
                        <button
                          className="doc-btn border-0 bg-transparent"
                          onClick={() => downloadDocument(document.id)}
                        >
                          <img src={downloadIMG} width="25" alt="" />
                        </button>
                      </div>
                      {(document.signable_document === 1 && (document.is_signed_valid === 0 || (document.is_signed_valid === null && document.signed_document_filename === null))) && (
                        <ActionsWrapperResultPage>
                          {' '}
                          {document.document_type.id === 13 && (
                            <div className="action-button" onClick={() => fireSignedDocUpload(document.document_type_id)}>
                              <img src={uploadIcon} alt="" width={25} />
                              <input ref={uploadPolizzaEmessa} type="file" name="" id="" className="d-none" onChange={(e) => uploadSignedDocument(document.id, e.target.files)} />

                            </div>
                          )}
                          {document.document_type.id === 32 && (
                            <div className="action-button" onClick={() => fireSignedDocUpload(document.document_type_id)}>
                              <img src={uploadIcon} alt="" width={25} />
                              <input ref={uploadQuietanzaSemestrale} type="file" name="" id="" className="d-none" onChange={(e) => uploadSignedDocument(document.id, e.target.files)} />

                            </div>
                          )}
                          {document.document_type.id === 29
                            && (
                              <div className="action-button" onClick={document.signed_document_filename === null ? () => fireSignedDocUpload(document.document_type.id) : () => { }}>
                                <img src={uploadIcon} alt="Carica" width={25} />
                                <input ref={uploadPreContractRef} type="file" name="" id="" className="d-none" onChange={(e) => uploadSignedDocument(document.id, e.target.files)} />

                              </div>
                            )}
                          {document.document_type.id === 28
                            && (
                              <div className="action-button" onClick={document.signed_document_filename === null ? () => fireSignedDocUpload(document.document_type.id) : () => { }}>
                                <img src={uploadIcon} alt="Carica" width={25} />
                                <input ref={uploadDichiarazioniDocRef} type="file" name="" id="" className="d-none" onChange={(e) => uploadSignedDocument(document.id, e.target.files)} />

                              </div>
                            )}
                          {document.document_type.id === 30
                            && (
                              <div className="action-button" onClick={document.signed_document_filename === null ? () => fireSignedDocUpload(document.document_type.id) : () => { }}>
                                <img src={uploadIcon} alt="Carica" width={25} />
                                <input ref={uploadQuestionarioAutoDocRef} type="file" name="" id="" className="d-none" onChange={(e) => uploadSignedDocument(document.id, e.target.files)} />

                              </div>
                            )}
                          {document.document_type.id === 12
                            && (
                              <div className="action-button" onClick={document.signed_document_filename === null ? () => fireSignedDocUpload(document.document_type.id) : () => { }}>
                                <img src={uploadIcon} alt="Carica" width={25} />
                                <input ref={uploadPrivacyRef} type="file" name="" id="" className="d-none" onChange={(e) => uploadSignedDocument(document.id, e.target.files)} />

                              </div>
                            )}
                        </ActionsWrapperResultPage>
                      )}
                    </div>
                    {(document.signable_document === 1 && document.is_signed_valid === 0 && document.signed_document_filename === null && document.motivation !== null) && (
                      <p className="text-danger mt-2">
                        {document.motivation}
                      </p>
                    )}
                  </div>
                  <div>
                    {document.signable_document === 1 ? (
                      document.is_signed_valid === null && document.signed_document_filename !== null ? (
                        <span className="badge bg-warning rounded-pill position-absolute top-25 end-0 me-2">In Verifica</span>
                      ) : document.is_signed_valid === 0 ? (
                        <span className="badge bg-danger rounded-pill position-absolute top-25 end-0 me-2">Firma Non Valida</span>
                      ) : document.is_signed_valid === null && document.signed_document_filename === null ? (
                        <span className="badge bg-warning rounded-pill position-absolute top-25 end-0 me-2">Da Firmare</span>
                      ) : document.is_signed_valid === 1 && document.signed_document_filename !== null ? (
                        <span className="badge bg-primary rounded-pill position-absolute top-25 end-0 me-2">Firmato</span>
                      ) : null
                    ) : (
                      <span className="badge bg-danger rounded-pill position-absolute top-25 end-0 me-2">Non Richiede Firma</span>
                    )}
                    {' '}

                  </div>
                </li>
              ))}
            </ol>
          </div>
        </div>
      </div>
    </TravelCompletedScreenStyles>
  );
}

export default TravelCompletedScreen;
