import React, { useEffect, useState } from 'react';
import {
  PrimaCardStyles, LeftCol, LogoPriceContainer, LogoContainer, Price, CompanyInformationContainer, CompanyInfoElements, QuoteInformationContainer, QuoteInformationItem, AquistaButtonsContainer, AquistaButton, PriceContainer, RightCol,
} from './PrimaCard.styles';
import infoIcon from '../../../../assets/redesign_icons/info-icon.svg';
import { guideType } from '../../../constants';
import { BACKEND_BASE_URL } from '../../../../config';
import { downloadFile } from '../../../../services/axios-client/axeHelpers';
import axe from '../../../../services/axios-client/axiosClient';
import PrimaInfoModal from '../Modal/PrimaInfoModal';
import { SavedButton } from './common/Buttons.styles';

// const Storage = new StorageService();

function PrimaCard({
  requestInfo,
  quotation,
  guaranteesPrima,
  selectQuotation,
}) {
  const [showModal, setShowModal] = useState(false);
  const [coverages, setCoverages] = useState([]);
  // const navigate = useNavigate();

  const openModal = () => {
    setShowModal(!showModal);
  };

  const downloadResourceDocs = async (id) => {
    const res = await axe.get(`/download-resource-document/${id}/0`, {
      responseType: 'blob',
    });

    downloadFile(res.data);
  };

  const coverageName = {
    rca: 'Responsabilità civile',
    furto_incendio: 'Furto e Incendio',
    eventi_naturali: 'Eventi Naturali',
    assistenza_stradale: 'Assistenza stradale',
    eventi_sociopolitici: 'Atti vandalici',
    tutela_legale: 'Tutela legale',
    cristalli: 'Cristalli',
    collisione: 'Kasco collisione',
    kasko: 'Kasco completa',
    infortuni_conducente: 'Infortuni conducente',
    rivalsa: 'Zero rivalsa per ebbrezza',
  };

  const calcAmount = (quotation_data_prima_guarantees, primaCoverages, key) => {
    const prices = quotation_data_prima_guarantees.find(
      (f) => f.slug === key,
    ).quotation_data_prima_guarantee_prices;
    const filterPrice = prices.filter(
      (p) => p.required_guarantees === ''
        || p.required_guarantees
          .split(',')
          .every((e) => primaCoverages.includes(e)),
    );
    return filterPrice.reduce((prev, curr) => (prev.discounted < curr.discounted ? prev : curr));
  };

  const coverageAmount = (
    { quotation_data_prima: { quotation_data_prima_guarantees } },
    primaCoverages,
    coverageSlug,
  ) => calcAmount(
    quotation_data_prima_guarantees,
    primaCoverages,
    coverageSlug,
  );

  const coverageLimitName = (
    { quotation_data_prima: { quotation_data_prima_guarantees } },
    coverageSlug,
  ) => quotation_data_prima_guarantees.find((s) => s.slug === coverageSlug)
    .limits_name;

  const convertToPrimaCoverages = (coverages) => {
    const cov = ['rca', ...coverages].filter(
      (c) => !['furto', 'incendio'].includes(c),
    );
    if (coverages.includes('furto') || coverages.includes('incendio')) {
      cov.push('furto_incendio');
    }
    return cov;
  };

  const primaPrices = {};
  let primaAllowedCoverages = []; // to know what coverages prima have

  quotation.quotation_data_prima.quotation_data_prima_guarantees.forEach(
    (guarantee) => {
      primaAllowedCoverages.push(guarantee.slug);
      guarantee.quotation_data_prima_guarantee_prices.forEach((price) => {
        if (price.required_guarantees !== '') {
          const g = price.required_guarantees.split(',');
          primaAllowedCoverages = [...primaAllowedCoverages, ...g];
        }
      });
      primaPrices[guarantee.slug] = guarantee.quotation_data_prima_guarantee_prices;
    },
  );

  primaAllowedCoverages = guaranteesPrima(quotation.quotation_data_prima);
  const convertedPrimaAllowedCoverages = convertToPrimaCoverages(
    primaAllowedCoverages,
  );

  const calculatedPrices = convertedPrimaAllowedCoverages
    .filter((adr) => {
      if (adr === 'rca') return true;

      for (let i = 0; i < coverages.length; i++) {
        if (adr === coverages[i]) return true;
      }
      return false;
    })
    .map((guarantee) => {
      const price = coverageAmount(quotation, coverages, guarantee);

      return {
        name: coverageName[guarantee],
        limits_name: coverageLimitName(quotation, guarantee),
        discounted: price.discounted,
        discounted_to_display: price.discounted_to_display,
      };
    });

  // const totalDiscountedToDisplay = calculatedPrices.reduce(
  //   (prev, curr) => prev + curr.discounted_to_display,
  //   0,
  // );

  let totalPriceWithGuarantees = 0;

  calculatedPrices.forEach((guarantee) => {
    totalPriceWithGuarantees += guarantee.discounted_to_display;
  });

  // const openGuaranteesModal = () => {
  //   setShowGuaranteesModalPrima(true);
  // };

  useEffect(() => {
    setCoverages(
      convertToPrimaCoverages(
        quotation.guarantees.map((guarantee) => guarantee.guarantees_slug),
      ),
    );
  }, [quotation.guarantees]);

  // const processQuote = () => {
  //   if (quotation.product_id === 2) {
  //     Storage.removeMotoQuoteAnswers();
  //     Storage.removeVehicleOwnerData();
  //     Storage.removeVehicleDriverData();
  //   } else {
  //     Storage.removeVehicleQuoteAnswers();
  //     Storage.removeVehicleOwnerData();
  //     Storage.removeVehicleDriverData();
  //   }

  //   if (quotation.product_id == 1) {
  //     navigate(`/quotes/auto/purchase/${quotation.id}`);
  //   }

  //   if (quotation.product_id == 3) {
  //     navigate(`/quotes/autocarro/purchase/${quotation.id}`);
  //   }

  //   if (quotation.product_id == 2) {
  //     navigate(`/quotes/moto/purchase/${quotation.id}`);
  //   }
  // };

  return (
    <>
      {/* {showGuaranteesModalPrima && (
        <GuaranteesModalPrima
          setShowGuaranteesModalPrima={setShowGuaranteesModalPrima}
          coverages={coverages}
          quote={quotation}
          requestToken={requestToken}
        />
      )} */}
      {showModal && (
        <PrimaInfoModal
          downloadResourceDocs={downloadResourceDocs}
          totalPriceWithGuarantees={totalPriceWithGuarantees}
          quotation={quotation}
          product_id={quotation.product_id}
          calculatedPrices={calculatedPrices}
          setShowModal={setShowModal}
        />
      )}
      <PrimaCardStyles className={quotation.is_aquista === 1 ? 'selected' : ''}>
        <LeftCol>
          {quotation.company_quality && (
            <div className="row mb-3">
              <div className="col-12">
                <div className={`p-2 badge ${quotation.company_quality.color === 'green' ? 'bg-success' : quotation.company_quality.color === 'yellow' ? 'bg-warning' : 'bg-danger'}`}>
                  <p className="mb-0 d-flex align-items-center justify-content-between gap-2">
                    Indice Di Qualità:
                    {' '}
                    <b className="fs-6">{parseFloat(quotation.company_quality.coefficiente).toFixed(2)}</b>
                  </p>
                </div>
              </div>
            </div>
          )}
          <LogoPriceContainer>
            <LogoContainer>
              <img
                src={BACKEND_BASE_URL + quotation.company.logo}
                alt={`${quotation.company.name} Logo`}
              />
            </LogoContainer>
            <PriceContainer>
              <Price>
                <h5>Prezzo annuo:</h5>
                {quotation.amount_before_discount > quotation.amount && (
                  <p>
                    &euro;
                    {Number(quotation.amount_before_discount).toLocaleString('it-IT', {
                      minimumFractionDigits: 2,
                    })}
                  </p>
                )}
                <h2>
                  &euro;
                  {Number(quotation.amount).toLocaleString('it-IT', {
                    minimumFractionDigits: 2,
                  })}
                </h2>
              </Price>
            </PriceContainer>
          </LogoPriceContainer>
          <CompanyInformationContainer>
            <CompanyInfoElements onClick={() => openModal()}>
              <img src={infoIcon} alt="" />
              <p>Informazione</p>
            </CompanyInfoElements>
          </CompanyInformationContainer>
          <QuoteInformationContainer>
            <QuoteInformationItem>
              <h6>Compagnia:</h6>
              <p>Ipitq ma</p>
            </QuoteInformationItem>
            <QuoteInformationItem>
              <h6>Emissione:</h6>
              <p>Diretta</p>
            </QuoteInformationItem>
            <QuoteInformationItem>
              <h6>Guida:</h6>
              <p>
                {guideType.find((v) => v.id === requestInfo.guide_type).name}
              </p>
            </QuoteInformationItem>
            <QuoteInformationItem>
              <h6>Frequenza pagamento:</h6>
              <p>
                {quotation.payment_frequency === 1 ? 'Annuo' : 'Semestrale'}
              </p>
            </QuoteInformationItem>
          </QuoteInformationContainer>
          <QuoteInformationContainer>
            <QuoteInformationItem>
              <h6>Risarcimento:</h6>
              <p>
                Rc con
                {' '}
                <b>Risarcimento</b>
                {' '}
                <br />
                {' '}
                <b>Diretto</b>
                {' '}
                e
                {' '}
                <b>RFS</b>
              </p>
            </QuoteInformationItem>
            <QuoteInformationItem>
              <h6>Massimale:</h6>
              <p>
                <b>6'450'000€</b>
                {' '}
                Danni a persone
                <br />
                <b>1'050'000€</b>
                {' '}
                Danni a cose
              </p>
            </QuoteInformationItem>
          </QuoteInformationContainer>
        </LeftCol>
        <RightCol className="mt-auto">
          {/* <SelectedGuaranteesContainer>
            <h2>
              <b>Garanzie</b>
              {' '}
              Aggiunte
            </h2>
            <GuaranteesList>
              {calculatedPrices.map((guarantee, index) => (
                <GuaranteesListItem key={index}>
                  <h6>
                    <span />
                    {' '}
                    {guarantee.name}
                  </h6>
                  <p>
                    &euro;
                    {' '}
                    {Number(guarantee.discounted_to_display).toLocaleString(
                      'it-IT',
                      { minimumFractionDigits: 2 },
                    )}
                  </p>
                </GuaranteesListItem>
              ))}
            </GuaranteesList>
          </SelectedGuaranteesContainer> */}
          <AquistaButtonsContainer>
            {/* <AddGuaranteesButton onClick={() => openGuaranteesModal()} className="result-moto-eight-step">Seleziona Garanzie</AddGuaranteesButton> */}
            {requestInfo.processing_quotation_id !== quotation.id ? (
              <AquistaButton
                onClick={() => selectQuotation(quotation.id)}
                className="result-moto-nine-step purchase-quote-nine-step"
              >
                Seleziona
              </AquistaButton>
            ) : requestInfo.processing_quotation_id === quotation.id && <SavedButton>Aggiunto</SavedButton>}
          </AquistaButtonsContainer>
        </RightCol>
      </PrimaCardStyles>
    </>
  );
}

export default PrimaCard;
