import { createSlice } from '@reduxjs/toolkit';

import { callerCreator } from '../callerCreator';

const initialState = {
  not_saved_requests: {
    loading: true,
    error: null,
    insuranceRequestsNotSaved: [],
    page_number: 1,
    total_pages: 1,
  },
  metlife_quotes: {
    loading: true,
    error: null,
    quoteId: null,
    quote: {
      request_id: '',
      pack_type: '',
      company: '',
      plan: '',
      name: '',
      dc: '',
      ip: '',
      diaria_ricovero: '',
      diaria_gesso: '',
      premio_annuo: '',
      ulc: '',
      ulc_premio_aggiuntivo: '',
      rsm: '',
      rsm_premio_aggiuntivo: '',
    },
    customer: {
      name: '',
      surname: '',
      gender: '',
      fiscal_code: '',
      phone: '',
      email: '',
      date_of_birth: '',
      country_of_birth_code: '',
      province_of_birth_code: '',
      commune_of_birth_code: '',
      born_abroad: 0,
      residence_province_code: '',
      residence_commune_code: '',
      postal_code: '',
      address: '',
      house_number: '',
      civil_status_id: 1,
      education_level_id: 4,
      profession_id: 13,
    },
    extensions: [],
    isSaving: false,
    saved: false,
    insuranceRequest: null,
  },
  auto_quotes: {
    loading: true,
    requestInfo: null,
    quotes: null,
    failedQuotes: null,
    noQuotes: false,
    coverages: [],
    related_quotations: [],
    markupPercentage: 0,
    preventivas_quotes: [],
    sortBy: 'amount',
    error: null,
    report: null,
    loadingReport: false,
    quotations: null,
    addingQuote: false,
    processingQuote: null,
    tutelaLegaleQuote: null,
    assistenzaStradaleQuote: null,
    infortuniConducenteQuote: null,
    cristalliQuote: null,
    rivalsaQuote: null,
  },
  profession_quotes: {
    loading: true,
    extensions: null,
    userData: null,
    profession: null,
    quoteId: null,
    selling_point: 0,
    distance_token: null,
    customer: null,
    error: null,
    quotations: null,
    addingQuote: false,
    processingQuote: null,
    tutelaLegaleQuote: null,
  },
  infodrive_quotes: {
    loading: true,
    insuranceRequest: null,
    quote: null,
    error: null,
    markupPercentage: 0,
    isSaving: false,
    saved: false,
    failedQuotation: null,
    updateMarkupLoading: false,
  },
  travel_quotes: {
    loading: true,
    insuranceRequest: null,
    quotations: [],
    error: null,
    failedQuotations: [],
    customer: null,
    quotes: null,
    addingQuote: false,
    processingQuote: null,
    tutelaLegaleQuote: null,
  },
  commercial_quotes: {
    loading: true,
    insuranceRequest: null,
    quotations: [],
    extensions: null,
    error: null,
    customer: null,
  },
  moto_quotes: {
    loading: true,
    requestInfo: null,
    quotes: null,
    failedQuotes: null,
    noQuotes: false,
    coverages: [],
    related_quotations: [],
    markupPercentage: 0,
    preventivas_quotes: [],
    error: null,
    quotations: null,
    addingQuote: false,
    processingQuote: null,
    tutelaLegaleQuote: null,
  },
  renewals: {
    loading: true,
    error: null,
    renewals: [],
  },
  tutela_legale_quotes: {
    loading: true,
    userData: null,
    quoteId: null,
    selling_point: 0,
    distance_token: null,
    customer: null,
    error: null,
    quotations: null,
    addingQuote: false,
    processingQuote: null,
    tutelaLegaleQuote: null,
  },
};

export const insuranceRequestsSlice = createSlice({
  name: 'insuranceRequests',
  initialState,
  reducers: {
    listNotSavedInsuranceRequestsPending: (state) => {
      state.not_saved_requests.loading = true;
      state.not_saved_requests.error = null;
    },
    listNotSavedInsuranceRequestsReject: (state, { payload }) => {
      state.not_saved_requests.loading = false;
      state.not_saved_requests.error = payload;
    },
    listNotSavedInsuranceRequestsSuccess: (state, { payload }) => {
      state.not_saved_requests.loading = false;
      state.not_saved_requests.insuranceRequestsNotSaved = payload.data;
      state.not_saved_requests.page_number = payload.current_page;
      state.not_saved_requests.total_pages = payload.total_pages;
    },
    notSavedRequestsReset: (state) => {
      state.not_saved_requests = { ...initialState.not_saved_requests };
    },

    metlifeQuotesDataUpdated: (state, { payload }) => {
      state.metlife_quotes = { ...state.metlife_quotes, ...payload };
    },
    infortuniQuotesUpdateExtensions: (state, { payload }) => {
      const { extensions } = state.metlife_quotes;
      let newExtensions = [];

      if (extensions.includes(payload)) {
        newExtensions = extensions.filter((extension) => extension !== payload);
      } else {
        newExtensions = [...extensions, payload];
      }
      state.metlife_quotes.extensions = newExtensions;
    },
    infortuniQuotesReset: (state) => {
      state.metlife_quotes = { ...initialState.metlife_quotes };
    },

    autoQuotesToggleCoverages: (state, { payload }) => {
      const coverageId = payload;
      const { coverages } = state.auto_quotes;
      let newCoverages = [];
      if (coverages.find((c) => c === coverageId) === undefined) {
        newCoverages = [...coverages, coverageId];
      } else {
        newCoverages = coverages.filter((c) => c !== coverageId);
      }
      state.auto_quotes.coverages = newCoverages;
    },
    autoQuotesCoveragesSet: (state, { payload }) => {
      state.auto_quotes.coverages = payload;
    },
    motoQuotesToggleCoverages: (state, { payload }) => {
      const coverageId = payload;
      const { coverages } = state.moto_quotes;
      let newCoverages = [];
      if (coverages.find((c) => c === coverageId) === undefined) {
        newCoverages = [...coverages, coverageId];
      } else {
        newCoverages = coverages.filter((c) => c !== coverageId);
      }
      state.moto_quotes.coverages = newCoverages;
    },
    motoQuotesCoveragesSet: (state, { payload }) => {
      state.moto_quotes.coverages = payload;
    },

    autoQuotesRequestInfoUpdated: (state, { payload }) => {
      state.auto_quotes.requestInfo = {
        ...payload.quotations[0],
      };
      state.auto_quotes.loading = false;
      state.auto_quotes.related_quotations = payload.related_quotations;
      state.auto_quotes.preventivas_quotes = payload.preventivas_quotes;
      state.auto_quotes.quotations = payload.quotations[0].quotes;
      state.auto_quotes.processingQuote = payload.processingQuote;
      state.auto_quotes.tutelaLegaleQuote = payload.tutelaLegaleQuote;
      state.auto_quotes.assistenzaStradaleQuote = payload.assistenzaStradaleQuote;
      state.auto_quotes.infortuniConducenteQuote = payload.infortuniConducenteQuote;
      state.auto_quotes.cristalliQuote = payload.cristalliQuote;
      state.auto_quotes.rivalsaQuote = payload.rivalsaQuote;
    },
    autoQuotesRequestInfoRejected: (state, { payload }) => {
      state.auto_quotes.error = payload.error;
      state.auto_quotes.loading = false;
    },
    motoQuotesRequestInfoUpdated: (state, { payload }) => {
      state.moto_quotes.requestInfo = {
        ...payload.quotations[0],
      };
      state.moto_quotes.loading = false;
      state.moto_quotes.related_quotations = payload.related_quotations;
      // state.moto_quotes.preventivas_quotes = payload.preventivas_quotes;
      state.moto_quotes.quotations = payload.quotations[0].quotes;
      state.moto_quotes.processingQuote = payload.quotations[0].processingQuote;
      state.moto_quotes.tutelaLegaleQuote = payload.quotations[0].tutelaLegaleQuote;
    },
    motoQuotesRequestInfoRejected: (state, { payload }) => {
      state.moto_quotes.error = payload.error;
      state.moto_quotes.loading = false;
    },
    autoQuotesDataUpdated: (state, { payload }) => {
      state.auto_quotes = { ...state.auto_quotes, ...payload };
    },
    motoQuotesDataUpdated: (state, { payload }) => {
      state.moto_quotes = { ...state.moto_quotes, ...payload };
    },
    autoQuotesReset: (state) => {
      state.auto_quotes = { ...initialState.auto_quotes };
    },
    motoQuotesReset: (state) => {
      state.moto_quotes = { ...initialState.moto_quotes };
    },

    professionQuotesDataUpdated: (state, { payload }) => {
      state.profession_quotes = { ...state.profession_quotes, ...payload };
    },
    professionQuotesDataUpdatedRejected: (state, { payload }) => {
      state.profession_quotes.error = payload;
      state.profession_quotes.loading = false;
    },
    professionQuotesReset: (state) => {
      state.profession_quotes = { ...initialState.profession_quotes };
    },
    infodriveQuotesDataUpdated: (state, { payload }) => {
      state.infodrive_quotes = { ...state.infodrive_quotes, ...payload };
    },
    infodriveQuotesReset: (state) => {
      state.infodrive_quotes = { ...state.infodrive_quotes };
    },
    infodriveQuoteUpdateMarkup: (state, { payload }) => {
      state.infodrive_quotes = { ...state.infodrive_quotes, ...payload };
    },
    travelQuotesDataUpdated: (state, { payload }) => {
      state.travel_quotes = { ...state.travel_quotes, ...payload };
    },
    travelQuotesReset: (state) => {
      state.travel_quotes = { ...initialState.travel_quotes };
    },
    travelQuotesInfoDataUpdate: (state, { payload }) => {
      state.travel_quotes.insuranceRequest = payload.insuranceRequest;
      state.travel_quotes.quotations = payload.quotations;
      state.travel_quotes.failedQuotations = payload.failed_quotations;
      state.travel_quotes.customer = payload.customer;
    },

    listRenewalsPending: (state) => {
      state.renewals.loading = true;
      state.renewals.error = null;
    },
    listRenewalsReject: (state, { payload }) => {
      state.renewals.loading = false;
      state.renewals.error = payload;
    },
    listRenewalsSuccess: (state, { payload }) => {
      state.renewals.loading = false;
      state.renewals.renewals = payload.renewals;
    },
    listRenewalsReset: (state) => {
      state.renewals = { ...initialState.renewals };
    },
    commercialQuotesDataUpdated: (state, { payload }) => {
      state.commercial_quotes = { ...state.commercial_quotes, ...payload };
    },
    commercialQuotesReset: (state) => {
      state.commercial_quotes = { ...initialState.commercial_quotes };
    },
    tutelaLegaleQuotesDataUpdated: (state, { payload }) => {
      state.tutela_legale_quotes = { ...state.tutela_legale_quotes, ...payload };
    },
    tutelaLegaleQuotesDataUpdatedRejected: (state, { payload }) => {
      state.tutela_legale_quotes.error = payload;
      state.tutela_legale_quotes.loading = false;
    },
    tutelaLegaleQuotesReset: (state) => {
      state.tutela_legale_quotes = { ...initialState.tutela_legale_quotes };
    },
  },
});

export default callerCreator(insuranceRequestsSlice.actions);
