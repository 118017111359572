import React from 'react';
import {
  BoxWrapper,
  ClientBox,
} from './CommonPurchase.styles';
import { guideType, primaQuoteTypes } from '../../../../constants';
import MessagesModal from '../../../elements/MessagesModal';

function InfoBoxMoto({
  insuranceRequest,
  statues,
  quoteStatus,
  messagesModalData,
  rc_quotation,
}) {
  const getTotal = () => {
    let primaGuaranteesPrices = 0;

    if (rc_quotation.company_id === 2) {
      rc_quotation.guarantees.forEach((guarantee) => {
        primaGuaranteesPrices += parseFloat(guarantee.amount) || 0;
      });
    }

    const rcTotalAmount = rc_quotation.amount;

    return parseFloat(rcTotalAmount + primaGuaranteesPrices).toFixed(2);
  };

  return (
    <>
      <BoxWrapper>
        <div className="row gap-2 gap-xl-0">
          <div className="col-12 col-xl-4">
            <ClientBox>
              <h6>Cliente:</h6>
              <h5>
                {insuranceRequest.customer.gender !== 'G'
                  ? `${insuranceRequest.customer.name
                  } ${insuranceRequest.customer.surname}`
                  : insuranceRequest.customer.business_name}
              </h5>
              <h6 className="mt-2">Veicolo:</h6>
              <h5>
                {insuranceRequest.moto.version.full_description}
              </h5>
              <h6 className="mt-2">Veicolo:</h6>
              <h5>
                {insuranceRequest.moto.moto_plate}
              </h5>
              <h6 className="mt-2">Decorrenza:</h6>
              <h5>
                {new Date(
                  insuranceRequest.policy_effective_date,
                ).toLocaleDateString('it-IT')}
              </h5>
              <h6 className="mt-2">Tipo Di Guida:</h6>
              <h5>
                {guideType.find((v) => v.id === insuranceRequest.guide_type).name}
              </h5>
              <h6 className="mt-2">Frequenza Pagamento</h6>
              <h5>
                {rc_quotation.payment_frequency === 1 ? 'Annuale' : 'Semestrale'}
              </h5>
              <h6 className="mt-2">Status:</h6>
              <h5>
                {statues.find((st) => st.id === quoteStatus).name}
              </h5>
            </ClientBox>
          </div>
          <div className="col-12 col-xl-8">
            <ClientBox>
              <h4>Informazioni Prodotti</h4>
              <hr />
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Prodotto</th>
                    <th scope="col">Compagnia</th>
                    <th scope="col">Frequenza Pagamento</th>
                    <th scope="col">Premio</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{rc_quotation.product.name}</td>
                    <td>
                      {rc_quotation.company_id !== null ? rc_quotation.company.name : rc_quotation.preventivas_company.company_name}
                    </td>
                    <td>{rc_quotation.payment_frequency === 1 ? 'Annuale' : 'Semestrale'}</td>
                    <td>
                      {parseFloat(rc_quotation.amount)}
                      {' '}
                      &euro;
                    </td>
                  </tr>
                  {(rc_quotation !== null && rc_quotation.company_id === 2) && (
                    rc_quotation.guarantees.map((guarantee, index) => (
                      <tr key={index}>
                        <td>{primaQuoteTypes.find((type) => type.id === guarantee.guarantees_slug).name}</td>
                        <td>
                          Prima.it
                        </td>
                        <td>Annuale</td>
                        <td>
                          {' '}
                          {parseFloat(guarantee.amount)}
                          {' '}
                          €
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <td className="fw-bold">
                      Totale
                    </td>
                    <td />
                    <td />
                    <td className="fw-bold">
                      &euro;
                      {' '}
                      {getTotal()}
                    </td>
                  </tr>
                </tfoot>
              </table>
              {/* <hr />
              <ContactBox
                as="button"
                data-bs-toggle="modal"
                data-bs-target="#messages-modal"
              >
                <img src={contactSupport} alt="Conttato Suporto" />
                {insuranceRequest.threads.length > 0 && insuranceRequest.threads[0].messages.filter((mes) => mes.read_by_broker === 0).length > 0 ? <MessageNumber>{insuranceRequest.threads[0].messages.filter((mes) => mes.read_by_broker === 0).length}</MessageNumber> : ''}
                <h5>{insuranceRequest.threads.length > 0 ? 'Apri Chat' : 'Apri Nuova Chat'}</h5>
              </ContactBox> */}
            </ClientBox>
          </div>
        </div>
      </BoxWrapper>
      <MessagesModal
        {...messagesModalData}
        threads={insuranceRequest.threads}
      />
    </>
  );
}

export default InfoBoxMoto;
